function headerLoad(headerUrl, textId, color) {
  fetch(headerUrl)
    .then((response) => response.text())
    .then((html) => {
      document.getElementById("admin-header").innerHTML = html;

      const surveyRequestHeader = document.getElementById(textId);

      if (surveyRequestHeader) {
        surveyRequestHeader.style.color = color;
      }
    })
    .catch((error) => {
      console.error("Failed to load the header: ", error);
    });
}

function subContainerLoad(subUrl, boxId) {
  fetch(subUrl)
    .then((response) => response.text())
    .then((html) => {
      document.getElementById(boxId).innerHTML = html;
    })
    .catch((error) => {
      console.error("Failed to load the sub-container: ", error);
    });
}
